<template>
  <div class="container my-4">
    Customers
    <div class="columns">
      <div class="column is-9">
        <div>
          <!-- Customers for {{ selectedCamp.name }} -->
          <!-- <a @click.prevent="create_contract">
            <i class="fa fa-plus"></i>
          </a> -->
        </div>
        <ag-grid-vue
          style="width: 100%; height: 200px"
          class="ag-theme-balham my-4"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :animateRows="true"
          @grid-ready="onGridReady"
          :context="context"
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ViewContracts from "@/components/buttonscell/ViewContracts.vue";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
// import helpers from "@/helpers.js";
import Api from "@/services/Api";

import { AgGridVue } from "ag-grid-vue3";

export default {
  components: {
    AgGridVue,
  },

  data() {
    return {
      context: null,
      rowData: [],
      columnDefs: [
        {
          headerName: "Customer Name",
          field: "name",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },

        // {
        //   headerName: "Date of creation",
        //   field: "creation_date",
        //   filter: true,
        //   floatingFilter: true,
        //   valueFormatter: helpers.dateFormatter,
        //   sort: "desc",
        //   sortable: true,
        // },
        {
          headerName: "Actions",
          // field: "creation_date",

          cellRenderer: ViewContracts,
          cellRendererParams: this.context,
        },
      ],
      gridApi: null,
      gridColumnApi: null,
      selected_item: {},
      selectedType: {},
      rowSelection: "single",
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
    },
  },
  async created() {
    this.context = { componentParent: this };

    this.$document.title = "Customers";

    let a = await Api(true).post("customers/");
    debugger; // eslint-disable-line no-debugger
    let temp = a.data;
    this.rowData = temp;
  },
};
</script>

<style scoped></style>
